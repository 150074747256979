<template>
  <v-container
    fluid
    fill-height
    class="background-img"
    v-if="render"
  >
    <v-row>
      <v-col
        class="align-self-center"
        cols="6"
      >
        <v-img
          class="logo2"
          src="../assets/cot_logo2.png"
        >
        </v-img>
      </v-col>
      <v-col
        v-if="!passwordRecoveryMode && !isLoading"
        class="align-self-center"
      >
        <v-card class="elevation-12 size-card">
          <v-card-text class="pt-8">
            <v-form class="pl-10 pr-16">
              <v-text-field
                data-cy="login"
                :label="$t('t.Username')"
                v-model="username"
                :prepend-icon="$icon('i.User')"
                type="text"
                @keypress="validate"
              />

              <v-text-field
                data-cy="password"
                :label="$t('t.Password')"
                v-model="password"
                :prepend-icon="$icon('i.Lock')"
                :type="showAsPassword ? 'password' : 'text'"
                @keypress="validate"
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    @click="showAsPassword = !showAsPassword"
                  >
                    <v-icon>{{showAsPassword ? $icon('i.EyeOff') : $icon('i.Eye')}} </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <div
                data-cy="recover-password"
                @click="passwordRecoveryMode = true"
                class="d-flex justify-end"
              >{{$t('t.RecoverPassword')}}</div>
              <v-checkbox
                data-cy="remember-me"
                v-model="rememberMe"
                :label="$t('t.RememberMe')"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="pt-2 pl-16 pr-16">
            <v-btn
              data-cy="login-button"
              class="x1"
              large
              rounded
              block
              justify-center
              color="primary"
              @click="login"
              :loading="is_working"
              v-ripple
            >
              <v-icon class="mr-2">{{$icon('i.Login')}}</v-icon>
              <span>{{$t('t.Connect')}}</span>

            </v-btn>
          </v-card-actions>

          <div
            data-cy="recaptcha-message"
            class="recatcha-disclaimer caption"
            v-if="!!grecaptchaSiteKey"
            v-html="recaptchaDisclaimer"
          ></div>
        </v-card>
      </v-col>
      <v-col
        v-else-if="!isLoading"
        class="align-self-center"
      >
        <v-card class="elevation-12 size-card">
          <v-card-title class="pl-10">
            <v-btn
              class="mr-2 elevation-1"
              icon
              color="primary"
              @click="passwordRecoveryMode = false"
            >
              <v-icon>{{$icon('i.ArrowLeft')}}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="py-3">
            <v-card-title class="pl-10 pr-16">
              <div>{{$t('t.RecoverPasswordTitle')}}</div>
            </v-card-title>
            <div class="pl-10 pr-16">{{$t('t.RecoverPasswordExplanation')}}</div>
            <v-form class="pt-4 pl-10 pr-16">
              <v-text-field
                :label="`${$t('t.Username')} / ${$t('t.Email')}`"
                v-model="username"
                :prepend-icon="$icon('i.User')"
                type="text"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="pt-4 pl-16 pr-16">
            <v-btn
              class="x1"
              large
              rounded
              block
              justify-center
              color="primary"
              @click="recoverPassword"
              :disabled="!username.length"
              :loading="is_working"
              v-ripple
            >
              <span>{{$t('t.RecoverPasswordButton')}}</span>
              <v-icon large>{{$icon('i.ChevronRight')}}</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-else>
        <v-card
          class="size-card"
          style="min-height: unset"
        >
          <v-card-text>
            <v-skeleton-loader type="image, actions" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      data-cy="snackbar-error"
      v-model="showError"
      color="error"
      :timeout="timeout"
    >{{ errorMessage }}</v-snackbar>
    <v-snackbar
      data-cy="snackbar-info"
      v-model="showInformation"
      color="info"
      :timeout="timeout"
    >{{ informationMessage }}</v-snackbar>
  </v-container>
</template>

<script>
import licenseController from '@/licenseController'
import themeController from '@/themeController'

export default {
  name: 'Login',
  data: () => ({
    timeout: 6000,
    errorMessage: null,
    informationMessage: null,
    showError: false,
    showInformation: false,
    isLoading: false,
    is_working: false,
    username: '',
    password: '',
    passwordRecoveryMode: false,
    showAsPassword: true,
    rememberMe: false,
    render: false,
    grecaptchaSiteKey: null
  }),
  async created () {
    const authentication = JSON.parse(window.localStorage.getItem('authentication'))
    if (authentication?.expiration > Date.now()) {
      const isTokenValid = await this.$http().authenticateFromLocalStorage()
      if (isTokenValid) {
        this.redirect()
        return
      }
    }
    this.render = true
    this.grecaptchaSiteKey = (await this.$http().get('core/authentication/captchaSiteKey')).data
  },
  async mounted () {
    this.isLoading = true
    await themeController.loadDefaultTheme().catch(() => { })
    this.$vuetify.theme.isDark = false
    this.$vuetify.theme.themes.light.primary = '#ffffff'
    this.$vuetify.theme.themes.light.primary = themeController.primary
    this.isLoading = false
    licenseController.resetLicenseStatus()
  },
  computed: {
    recaptchaDisclaimer () {
      const disclaimer = this.$t('t.RecaptchaDisclaimer')
      const culture = this.$i18n.locale
      const privacy = `<a href="https://policies.google.com/privacy?hl=${culture}">${this.$t('t.PrivacyPolicy')}</a>`
      const tos = `<a href="https://policies.google.com/terms?hl=${culture}">${this.$t('t.TermsOfService')}</a>`

      return disclaimer.replace('{0}', privacy).replace('{1}', tos)
    }
  },
  methods: {
    async login () {
      const captchaToken = await this.getCaptchaToken()

      this.is_working = true
      try {
        await this.$http().authenticate(this.username, this.password, this.rememberMe, captchaToken)
        this.redirect()
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.errorMessage = this.$t('t.InvalidUserNameOrPassword')
          this.showError = true
        } else if (e.response.status === 403) {
          this.errorMessage = this.$t('t.UserAccountIsLocked')
          this.showError = true
          this.isLocked = true
        } else if (e.response.status === 406) {
          const response = e.response.data

          this.$router.push(`/changePassword/${response.hash}?error=${response.error}`)
        } else {
          this.errorMessage = e?.response?.data?.message ?? this.$t('t.InternalError')
          this.showError = true
        }

        this.is_working = false
      }
    },
    redirect () {
      import('@/recentsController').then(r => r.default.loadRecents())
        .finally(() => {
          this.$router.replace({
            path: this.$router.currentRoute.query.redirect || '/'
          }).catch(e => { })
        })
    },
    async recoverPassword () {
      if (this.username.length) {
        let username = null
        let email = null

        if (this.username.includes('@')) { // TODO Surely there is a better way to detect if an email has been sent...
          email = this.username
        } else {
          username = this.username
        }

        const captchaToken = await this.getCaptchaToken()

        await this.$http().post('/core/authentication/password/forgot', {
          Username: username,
          Email: email,
          captchaToken
        }).catch(e => {
          // Fail silently
        }).finally(() => {
          this.informationMessage = this.$t('t.RecoverPasswordMailSent')
          this.showInformation = true
          this.passwordRecoveryMode = false
        })
      }
    },
    validate (e) {
      if (e.key === 'Enter') {
        this.login()
      }
    },
    async getCaptchaToken () {
      if (!this.grecaptchaSiteKey) {
        return null
      }

      await new Promise(resolve => window.grecaptcha.ready(resolve))
      return window.grecaptcha.execute(this.grecaptchaSiteKey, { action: 'zarma' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.background-img
  background-image url('../assets/background-login2.png')
  background-size cover

.size-card
  max-width 680px
  min-height 386px
  margin-left auto
  margin-right auto
  background-color #fff !important

.logo2
  width 100%
  max-width 600px
  max-height 600px
  margin-left auto
  margin-right auto
  z-index 2

.x1
  margin-left auto
  margin-right auto
  margin-bottom 24px

.recatcha-disclaimer
  display inline-block
  margin-bottom 1em
  width 100%
  text-align center
</style>
